import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import Banner from "../components/Banner";
import Download from "../components/Download";
import Explainer from "../components/Explainer";

import Loader from "../components/Loader";
import ProductRetailerTable from "../components/ProductRetailerTable";
import Helmet from "react-helmet";

import { IProduct, IProductPrice } from "../@types/globals";
import _ from "underscore";
import * as dayjs from "dayjs";

// const EVENT_ID = "01CZ5GESFFWB2X70RYDYXCEY0R";
const EVENT_ID = "01FYB6828T6KB1T80BN7NSQA3R";

const API = "https://api.askhargapedia.com/graphql";

const fetchEvent = async (id: string, retailers?: string[]) => {
  const query = `
  query GetEventDetailsForWeb($id: ID!, $retailers: [ID]) {
    event(id: $id, disable_targeting: true) {
      products {
        id
        short_name
        slug
        image {
          small
        }
        prices(retailers: $retailers){
          id
          retailer {
            id
            name
          }
          price
          currency
          location
          source_type
          modified_at
        }
      }
      retailers {
        id
        name
        logo {
          center
        }
      }
    }
  }
  `;
  const variables = { id, retailers };
  const response = await fetch(API, {
    method: "POST",
    // mode: 'cors',
    headers: {
      "Content-Type": "application/json",
      "x-api-key": "5ba1d496-314f-4d3e-ad0e-4042ef8a49e9",
      "x-application-version": "minisite",
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });
  if (response.ok) {
    const { data, error } = await response.json();
    return { data, error };
  }
  return { data: null, error: Error("No response") };
};

export default function PriceDashboard() {
  const [products, setProducts] = useState([]);
  const [retailers, setRetailers] = useState([]);

  const getLastUpdated = (products: IProduct[]): string => {
    // get the latest date out of all the prices
    const prices: IProductPrice[] = [];
    products.forEach((p) => {
      prices.push(...p.prices);
    });

    const dates = prices.map((p) => dayjs(p.modified_at).unix());
    const final = _.sortBy(dates).reverse();
    if (final && final.length > 0) {
      const date = final[0];
      return `Dikemaskini: ${dayjs.unix(date).format("D MMM YYYY, hh:mmA")}`;
    }
    return "";
  };

  useEffect(() => {
    fetchEvent(
      EVENT_ID,
      retailers.length > 0 ? retailers.map((e) => e.id) : null
    ).then(({ data }) => {
      if (data && data.event) {
        setProducts(data.event.products);
        if (retailers.length === 0) {
          setRetailers(data.event.retailers);
        }
      }
    });
  }, [retailers]);

  if (products.length === 0) {
    return <Loader />;
  }

  return (
    <Layout pageName="price-dashboard-main">
      <Helmet>
        <title>Jejak harga bersama Hargapedia | Sinar Harian</title>
        <link
          rel="icon"
          type="image/png"
          href="https://www.sinarharian.com.my/themes/sinarharian/images/favicon.ico?v=4"
        />
      </Helmet>

      <Header />
      <ProductRetailerTable
        cheapestGradient={["#00C853", "#00C853", "#4CAF50"]}
        products={products}
        retailers={retailers}
      />
      <div className="disclaimer">
        <div>
          <span>Penafian:</span> Semua harga dan maklumat di sini disahkan betul
          pada masa muat naik tetapi tertakluk kepada perubahan tanpa notis
          terlebih dahulu oleh peruncit masing-masing.
        </div>
        <div className="updated">{getLastUpdated(products)}</div>
      </div>
      <div className="row center-xs">
        <div className="col-gutter-tb col-sm-10 col-xs-12">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <Banner id="8a426e18-8f02-11ec-bf75-024c096e90c6" />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <Banner id="ac2cf3b8-8f02-11ec-aa00-024c096e90c6" />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <Banner id="ce20de94-8f02-11ec-ae6e-024c096e90c6" />
            </div>
          </div>
        </div>
      </div>

      <div className="row center-xs">
        <div className="col-sm-10 col-xs-12">
          <Explainer />
        </div>
      </div>
      <Download />
    </Layout>
  );
}
