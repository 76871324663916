import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";

export default function Header() {
  return (
    <header className="header-dashboard">
      <div className="logo-sinar">
        <img src="../../logo-sinar.svg" alt="Sinar Harian" />
      </div>

      <div>
        <span className="byline">Jejak harga bersama</span>
        <OutboundLink href="https://www.hargapedia.com.my" target="_blank">
          <img
            src="../../logo-white.svg"
            alt="Hargapedia"
            className="logo-hp"
          />
        </OutboundLink>
      </div>
    </header>
  );
}
