import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { OutboundLink } from "gatsby-plugin-google-gtag";

export default function Download() {
  return (
    <div className="cta-container">
      <div className="row center-xs">
        <div className="col-sm-8 col-xs-12 col-no-gutter ">
          <div className="row">
            <div className="col-md-7 col-xs-12  download-info">
              <h2>
                Guna Hargapedia untuk Banding Harga, Semak Promosi &amp; Dapat
                Baucar!
              </h2>
              <p>Muat turun Hargapedia dari gedung aplikasi anda hari ini!</p>

              <div className="btn-group">
                <OutboundLink
                  href="https://itunes.apple.com/us/app/hargapedia/id1315874967?ls=1&mt=8"
                  target="_blank"
                >
                  <img
                    src="../../btn-apple.svg"
                    alt="Get Hargapedia - The grocery, health &amp; beauty, price comparison app from the Apple App Store"
                  />
                </OutboundLink>
                <OutboundLink
                  href="https://play.google.com/store/apps/details?id=com.askhargapedia.app"
                  target="_blank"
                >
                  <img
                    src="../../btn-google.svg"
                    alt="Get Hargapedia - The grocery, health &amp; beauty, price comparison app from the Google Play Store"
                  />
                </OutboundLink>
                <OutboundLink
                  href="https://appgallery5.huawei.com/#/app/C102195777"
                  target="_blank"
                >
                  <img
                    src="../../btn-huawei.svg"
                    alt="Get Hargapedia - The grocery, health &amp; beauty, price comparison app from Huawei AppGallery"
                  />
                </OutboundLink>
              </div>
            </div>

            <div className="col-md-5 col-xs-12 col-no-gutter">
              <StaticImage
                placeholder="blurred"
                src="../../images/mockup-download.png"
                alt="Hargapedia App"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
