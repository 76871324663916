import React from "react";

export default function Download() {
  return (
    <div className="explainer">
      <h3>Banding Harga Barang Harian di Pasaraya Malaysia</h3>
      <p>
        Dengan kos sara hidup yang semakin tinggi & kenaikan harga barangan
        secara senyap, kita sebagai warga Malaysia haruslah bijak berbelanja,
        terutamanya semasa membeli barang harian yang mungkin akan menjadi satu
        beban jika kita tidak mengikuti bajet keluarga semasa membeli.
        Sebenarnya, terdapat pelbagai cara untuk menjimatkan wang dan bijak
        berbelanja, salah satu cara yang bukan sahaja boleh menjimatkan wang
        tetapi juga boleh menjimatkan masa dan tenaga adalah dengan menggunakan
        aplikasi <a href="https://www.hargapedia.com.my">Hargapedia.</a>
      </p>

      <h3>3 Sebab Untuk Jimat Berbelanja</h3>
      <ol>
        <li>Kos keperluan asas menjadi semakin tinggi.</li>
        <li>
          Kenaikan harga barangan secara senyap, pengurangan saiz barang harian
          tanpa notis.
        </li>
        <li>
          Pendapatan yang meningkat lebih perlahan daripada inflasi menyebabkan
          corak perbelanjaan isi rumah yang melebihi pendapatan.
        </li>
      </ol>

      <h3>Kebaikan Menggunakan Aplikasi Hargapedia</h3>
      <ol>
        <li>Jimat 30% dalam perbelanjaan barang harian</li>
        <li>
          Banding harga barang harian kegemaran antara pasaraya kegemaran anda.
        </li>
        <li>
          Semak promosi terkini dari katalog dan surat khabar yang dipaparkan
          dari pelbagai pasaraya seperti Lotus's, Giant, Mydin, 99 Speedmart,
          Guardian, Watsons & banyak lagi.
        </li>
        <li>
          Semak promosi hebat{" "}
          <a
            href="https://www.hargapedia.com.my/vouchers/r/shopee"
            target="_blank"
          >
            Shopee,
          </a>{" "}
          <a
            href="https://www.hargapedia.com.my/vouchers/r/lazada"
            target="_blank"
          >
            Lazada,
          </a>{" "}
          Lotus's Online,{" "}
          <a
            href="https://www.hargapedia.com.my/vouchers/r/watsons-1"
            target="_blank"
          >
            Watsons Online
          </a>{" "}
          dan kedai dalam talian lain.
        </li>
        <li>
          Tebus baucar percuma dari peruncit terkenal seperti{" "}
          <a
            href="https://www.hargapedia.com.my/vouchers/r/lazada"
            target="_blank"
          >
            Lazada,
          </a>{" "}
          <a
            href="https://www.hargapedia.com.my/vouchers/r/shopee"
            target="_blank"
          >
            Shopee,
          </a>{" "}
          <a
            href="https://www.hargapedia.com.my/vouchers/r/foodpanda"
            target="_blank"
          >
            foodpanda,
          </a>{" "}
          <a
            href="https://www.hargapedia.com.my/vouchers/r/grab"
            target="_blank"
          >
            GrabFood,
          </a>{" "}
          dan lain-lain.
        </li>
        <li>
          Banding harga barang elektronik seperti telefon pintar{" "}
          <a href="https://www.hargapedia.com.my/brand/apple" target="_blank">
            Apple,
          </a>{" "}
          <a href="https://www.hargapedia.com.my/brand/samsung" target="_blank">
            Samsung,
          </a>{" "}
          <a href="https://www.hargapedia.com.my/brand/oppo" target="_blank">
            Oppo,
          </a>{" "}
          tablet pintar{" "}
          <a href="https://www.hargapedia.com.my/brand/oppo" target="_blank">
            Huawei,
          </a>{" "}
          Samsung, alat perkakas rumah seperti{" "}
          <a href="https://www.hargapedia.com.my/brand/dyson" target="_blank">
            Dyson,
          </a>{" "}
          <a href="https://www.hargapedia.com.my/brand/tefal" target="_blank">
            Tefal,
          </a>{" "}
          <a href="https://www.hargapedia.com.my/brand/khind" target="_blank">
            Khind,
          </a>{" "}
          <a
            href="https://www.hargapedia.com.my/brand/pensonic"
            target="_blank"
          >
            Pensonic
          </a>{" "}
          dan sebagainya.
        </li>
      </ol>

      <p>
        Kesimpulannya, tujuan utama menggunakan aplikasi Hargapedia bukannya
        untuk dapati harga yang paling murah sahaja dan membeli di beberapa
        tempat, tetapi ia menjadi satu aplikasi yang memberitahu anda pasaraya
        mana yang mempunyai promosi yang paling banyak untuk barang harian yang
        anda perlu beli. Bukan itu sahaja, Hargapedia juga boleh memberitahu
        anda apabila terdapat promosi hebat. Beli barang harian semasa promosi
        lebih jimat berbanding tiada promosi.
      </p>
    </div>
  );
}
